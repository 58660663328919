@import url('https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700;800;900&display=swap');

.about {
    background: linear-gradient(to top, #ffffff, #b2b8bb);
    padding: 100px 0;
}

.about__description {
    width: 60%;
    padding: 0 25px;
}

.about__description p:last-of-type {
    margin: 20px 0 50px 0;
}

.about__description p span {
    font-weight: bold;
}

.about__image {
    width: 40%;
    padding: 0 25px;
}

.about__image img {
    width: 100%;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    .about {
        padding: 25px 0;
    }

    .about__description{
        width: 100%;
        padding: 0;
    }

    .about__description h2 {
        font-size: 21px;
    }

    .about__description p {
        font-size: 14px;
    }

    .about__description p:last-of-type {
        margin: 20px 0 30px 0;
    }

    .about .btn {
        font-size: 13px;
        font-weight: bold;
    }

    .about__image {
        margin-top: 30px;
        width: 80%;
        padding: 0;
    }

    .about__image img{
        object-fit: cover;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
   .about {
       padding: 50px 0;
   }

    .about__description {
        width: 60%;
        padding: 0 25px 0 0;
    }

    .about__description p {
        font-size: 14px;
    }

    .about .btn {
        font-size: 13px;
        font-weight: bold;
    }

    .about__image {
        width: 40%;
        padding: 0 0 0 25px;
    }
}