.contact {
    background: linear-gradient(to top, #ffffff, #b2b8bb);
    padding: 60px 0;
    position: relative;
}

.contact__content {
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 25px;
    padding: 20px 10px;
}

.contact .content__description h3 {
    color: #595b5e;
    font-size: 24px;
    font-weight: 600;
}

.contact .content__description h3::after {
    content: '';
    display: block;
    height: 2px;
    width: 11%;
    background: #595b5e;
    margin: 15px 0;
}

.contact .content__description p {
    font-style: italic;
    font-size: 15px;
}

.contact__info {
    width: 100%;
}

.info__address, .info__time, .info__email {
    display: flex;
    align-items: center;
    gap: 60px;
    text-align: left;
}

.contact__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact__info ul {
    list-style-type: none;
}

.contact__info p, ul {
    font-size: 16px;
    font-weight: 500;
}

/******************** MEDIA ********************/

@media screen and (max-width: 477px) {
    main section.contact#contact {
        padding-bottom: 20px;
    }

    .contact {
        padding: 10% 0;
    }

    .contact__content {
        padding: 0;
    }

    .contact .content__description h3 {
        font-size: 21px;
    }

    .contact .content__description p {
        font-size: 14px;
    }

    .contact__info p, ul li {
        font-size: 14px;
        font-weight: 600;
    }

    .contact__content, .contact__container .contact-form {
        width: 100%;
    }

    .contact__container .contact-form {
        padding: 30px 20px 40px;
    }

    .contact .width-10 {
        width: 19%;
    }

    .info__address, .info__time, .info__email {
        gap: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .contact {
        padding: 30px 0 15px;
    }

    .contact__content {
        width: 55%;
        gap: 20px;
        padding: 20px 25px 20px 10px;
    }

    .contact .content__description p {
        font-size: 14px;
    }

    .contact__info p, ul {
        font-size: 14px;
        font-weight: 600;
    }

    .info__address, .info__time, .info__email {
        gap: 28px;
    }
}