.custom {
    height: 100vh;
    width: 100vw;
    background: url('../../assets/images/img_1.jpeg') no-repeat top/cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom .custom__container {
    height: 100%;
}

.custom .custom__container .btn {
    margin-top: 150px;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    .custom {
        height: 33vh;
    }

    .custom .btn {
        font-size: 13px;
        font-weight: bold;
    }
}

@media screen and (min-width: 478px) and (max-width: 767px) {
    .custom {
        height: 80vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .custom {
        height: 62vh;
    }

    .custom .btn {
        font-size: 13px;
        font-weight: bold;
    }
}
