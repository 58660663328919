.consultation-content {
    padding: 100px 0;
    background: linear-gradient(to top, #AAAAAA, #595b5e);
}

.consultation-content__container {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.content-block {
    width: 80%;
}

.content-block p {
    color: #FFFFFF;
    padding: 30px 0 70px 0;
    font-size: 19px;
}

.content-block h2 {
    color: #FFFFFF;
}

.content-block h2::after {
    display: none;
}

.content-block a.btn {
    padding: 10px 15px;
    font-weight: bold;
    font-size: 18px;
}

/******************* MEDIA ********************/

@media screen and (max-width: 477px) {
    .content-block h2 {
        font-size: 21px;
    }

    .content-block p {
        font-size: 14px;
    }

    .content-block a.btn {
        font-size: 13px;
        font-weight: bold;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .consultation-content {
        padding: 50px 0;
    }

    .content-block a.btn {
        font-size: 15px;
    }

    .content-block p {
        font-size: 17px;
    }
}