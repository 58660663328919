.questions {
    padding: 100px 0;
    background: linear-gradient(to top, #ffffff, #b2b8bb);
}

.questions .content__description {
    margin-bottom: 25px;
    font-size: 19px;
    font-style: italic;
}

.questions .MuiPaper-root {
    background:linear-gradient(0deg, rgb(210, 210, 210) 0%, rgb(225, 230, 233) 100%);
}

.questions__list {
    margin-bottom: 15px;
    padding-left: 40px;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
   .questions {
       padding: 25px 0;
   }

    .questions h2{
        font-size: 21px;
    }

    .questions .content__description {
        font-size: 14px;
    }

    .questions .MuiTypography-root {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .questions {
        padding: 50px 0;
    }

    .questions .content__description {
        font-size: 14px;
    }

    .questions .MuiTypography-root {
        font-size: 14px;
    }
}
