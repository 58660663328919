main.main section.about-page {
    padding-top: 0;
}

.about-page h1 {
    width: 100%;
    color: #FFFFFF;
}

.about-page .main__container p {
    margin: 20px 0 0;
}

.about-page .main__container {
    display: flex;
    flex-direction: column;
}

.about-page-content {
    display: flex;
    align-items: center;
    padding-top: 100px;
}

.about-page .about__description {
}

.about-page .about__image {
    width: 40%;
}

.about-page .about__image img {
    border-radius: 15px;
}

@media screen and (max-width: 477px) {
    .about-page-content {
        flex-direction: column;
        padding-top: 40px;
    }

    .about-page .about__image {
        width: 80%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .about-page-content {
        padding-top: 50px;
    }
}