html, body {
    min-height: 100%;
    min-width: 320px;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #595b5e;
    font-size: 16px;
    line-height: 1.5;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

h1 {
    text-align: center;
    background: linear-gradient(to top, #AAAAAA, #595b5e);
    padding: 20px 0;
}

h1, h2 {
    color: #595b5e;
    font-family: 'Martel', serif;
    font-size: 42px;
    font-weight: 800;
}

h2::after {
    content: '';
    display: block;
    height: 3px;
    width: 11%;
    background: #595b5e;
    margin: 5px 0 20px 0;
}

h3, h4 {
    color: #FFFFFF;
    font-weight: normal;
}

.btn {
    padding: 7px 20px;
    background: linear-gradient(to left, #dee2e2, #939597);
    border-radius: 30px;
    font-size: 16px;
}

.btn:hover {
    background: linear-gradient(to left, #64656a, #e1e6e9);
}

img {
    border-radius: 15px;
}

[class*=__container] {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    [class*=__container] {
        max-width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 20px;
    }

    .wrapper {
        padding: 0 2px;
    }

    main.main > section {
        padding: 10% 0;
    }

    h1 {
        font-size: 25px;
        padding: 5px 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    [class*=__container] {
        max-width: 100%;
        padding: 0 25px;
    }

    h1 {
        padding: 10px 0;
        font-size: 30px;
    }

    h2 {
        font-size: 26px;
    }
}

/******************** ANIMATION *****************/

.header__menu .menu__item, .header__bottom p, .social__list,
h2, .btn, .footer__description, .footer__additionally, .footer__info,
.service-item {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 1.5s ease;
}

.header__menu .menu__item.visible, .header__bottom p.visible,
.social__list.visible, h2.visible, .btn.visible, .footer__additionally.visible,
.footer__description.visible, .footer__info.visible, .service-item.visible {
    opacity: 1;
    transform: translateX(0);
}


/*.custom .btn, .about .btn {*/
/*    position: relative;*/
/*    left: -100%;*/
/*    opacity: 0;*/
/*    animation: slideIn 1.5s ease-out forwards;*/
/*}*/

/*@keyframes slideIn {*/
/*    0% {*/
/*        left: -100%;*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        left: 0;*/
/*        opacity: 1;*/
/*    }*/
/*}*/

