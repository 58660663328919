.about-first {
    background: linear-gradient(to top, #ffffff, #b2b8bb);
    padding: 80px 0;
}

.about-first__image {
    width: 50%;
    padding: 0 25px;
}

.about-first__image img {
    width: 100%;
    object-fit: cover;
}

.about-first__description {
    width: 50%;
    padding: 0 25px;
}

.about-first__list {
    margin: 15px 0;
    list-style: none;
}

.about-first__list .list__item {
    font-style: italic;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    .about-first {
        padding: 25px 0;
    }

    .about-first__image {
        width: 90%;
        padding: 0;
    }

    .about-first__image img {
        width: 100%;
    }

    .about-first__description {
        width: 100%;
        padding: 0;
    }

    .about-first__description h2 {
        font-size: 21px;
    }

    .about-first__description p, ul li {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .about-first {
        padding: 50px 0;
    }

    .about-first__container {
        flex-direction: column;
    }

    .about-first__image {
        width: 50%;
        padding: 0;
    }

    .about-first__description {
        padding: 25px 0 0 0;
        width: 100%;
    }

    .about-first__description p, ul li {
        font-size: 14px;
    }
}