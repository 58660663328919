.reviews {
    background: linear-gradient(to top, #aaaaaa, #595b5e);
    padding: 80px 0 60px 0;
}

.reviews .reviews__container {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.reviews__content h2 {
    color: #FFFFFF;
}

.reviews__content h2::after {
    width: 13%;
    background-color: #b2b8bb;
    margin: 10px auto 15px;
}

.reviews__content p {
    color: #b2b8bb;
    font-style: italic;
}

.reviews__container .date {
    color: #f9fcfc;
}

.reviews__container h4 {
    color: #f9fcfc;
    font-size: 21px;
    font-weight: 500;
}

.reviews__container p.comment {
    margin: 20px 0;
    color: #d9d9df;
}

.reviews .swiper {
    width: 100%;
    padding: 50px 0;
}

.swiper-button-prev:after, .swiper-button-next::after {
    color: #b2b8bb;
    font-size: 25px;
}

.reviews__container img {
    position: relative;
    display: block;
    margin: 20px auto;
    text-align: center;
    text-transform: uppercase;
    color: #979797;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.reviews__container img:after {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid #979797;
    border-radius: 50%;
    background-color: #fff;
    content: attr(alt);
}

/******************************/

@media screen and (max-width: 477px) {
    .reviews {
        padding: 25px 0;
    }

    .reviews .reviews__container {
        gap: 10px;
    }

    .reviews .reviews__container h4 {
        font-size: 18px;
    }

    .reviews__container p.comment {
        font-size: 14px;
    }

    .reviews .swiper {
        padding: 0;
    }

    .reviews__content h2 {
        font-size: 21px;
    }

    .reviews .swiper-button-prev, .swiper-rtl .swiper-button-next,
    .reviews .swiper-button-next, .swiper-rtl .swiper-button-prev {
        top: 40%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .reviews {
        padding: 50px 0 0 0;
    }

    .reviews .swiper {
        padding: 10px 0 50px;
    }

    .reviews p {
        font-size: 14px;
    }
}