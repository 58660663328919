.header__top {
    padding: 2px 0;
    background: linear-gradient(to top, #f9fcfc, #b2b8bb);
}

.header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
}

.header__logo a.teeth {
    width: 55px;
}

.header__logo img {
    width: 100%;
}

.header__logo .text p {
    color: #7c7c7c;
    font-weight: 900;
    font-family: 'Martel', serif;
}

.header__logo .text p span.black {
    color: #222222;
    font-family: 'Martel', serif;
    font-weight: 900;
}

.header__logo .text .heading {
    font-size: 21px;
}

.header__logo .text .small {
    color: black;
    font-weight: 900;
    font-size: 11px;
    font-family: 'Martel', serif;
}

.menu__list {
    display: flex;
    align-items: center;
    gap: 40px;
    list-style: none;
}

.menu__item a {
    font-size: 18px;
    font-weight: bold;
    color: #595b5e;
    transition: color 400ms ease;
}

.menu__item a:hover {
    color: #222222;
}

.menu__item a::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: 2px;
    background-color: #222222;
    transition: width 400ms ease;
}

.menu__item a:hover::after {
    width: 100%;
    height: 2px;
}

.social__list {
    display: flex;
    gap: 15px;
    list-style: none;
}

.social__item a {
    display: block;
    border: 1px solid #595b5e;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    transition: all 300ms ease;

    background-image: url('../../assets/images/icons/fb-white.svg');
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center center;
}

.social__item a:hover {
    background-color: #595b5e;
}

.social__item a.phone {
    background-image: url('../../assets/images/icons/phone-grey.svg');
}

.social__item a.phone:hover {
    background-image: url('../../assets/images/icons/phone-white.svg');
}

.social__item a.mail {
    background-image: url('../../assets/images/icons/mail-grey.svg');
}

.social__item a.mail:hover {
    background-image: url('../../assets/images/icons/mail-white.svg');
}

.social__item a.facebook {
    background-image: url('../../assets/images/icons/fb-grey.svg');
}

.social__item a.facebook:hover {
    background-image: url('../../assets/images/icons/fb-white.svg');
}

.social__item a.instagram {
    background-image: url('../../assets/images/icons/inst-grey.svg');
}

.social__item a.instagram:hover {
    background-image: url('../../assets/images/icons/inst-white.svg');
}


.header__bottom {
    background: linear-gradient(to top, #6e6e6e, #222222);
    padding: 10px 0;
}

.header__bottom p {
    font-size: 15px;
    color: #FFFFFF;
}

.header__time {
    color: #b2b8bb;
    margin-left: 5px;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    header .header__top {
        padding: 5px 0 0 0;
    }

    header .header__wrapper {
        display: flex;
        align-items: center;
        gap: 110px;
    }

    body:has(header.open) {
        overflow: hidden;
    }

    header.open {
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: 20;
        background: linear-gradient(to top, #f9fcfc, #b2b8bb);
        transition: height 0.3s ease-in-out; /**/
    }

    header.open .header__bottom, header.open .header__top {
        background: rgba(255, 255, 255, 0);
    }

    header.open .header__top {
        height: 35vh;
    }

    header.open .header__container {
        gap: 40px;
        display: flex;
    }

    header.open .header__bottom * {
        background: rgba(255, 255, 255, 0);
        color: #393A3A;
    }

    header .header__menu,
    header .header__social,
    header .header__bottom {
        display: none;
    }

    header .header__burger {
        width: 30px;
        height: 20px;
        display: grid;
        gap: 2px;
    }

    header .header__burger span {
        display: block;
        height: 3px;
        width: 26px;
        background-color: #3A3A3A;
        transition: all 0.3s ease-in-out; /***/
    }

    header.open .header__burger {
        width: 40px;
        height: 10px;
    }

    header.open .header__open span {
        display: block;
        height: 2px;
        width: 100%;
        background-color: #3A3A3A;
    }

    header.open span:first-child {
        transform: rotate(-45deg);
        transform-origin: 15px 1px;
    }

    header.open span:nth-of-type(2) {
        display: none;
    }

    header.open span:last-child {
        transform: rotate(45deg);
        transform-origin: 19px 0;
    }

    header.open .header__menu,
    header.open .header__social,
    header.open .header__bottom {
        display: flex;
    }

    header.open .header__menu ul {
        flex-direction: column;
    }

    .header__bottom {
        padding: 120px 0 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    header .header__top {
        padding: 2px 0;
        height: auto;
    }

    header .header__wrapper {
        width: 95.5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
    }

    body:has(header.open) {
        overflow: hidden;
    }

    header.open {
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: 20;
        background: linear-gradient(to top, #f9fcfc, #b2b8bb);
        transition: height 0.3s ease-in-out;
    }

    header.open .header__bottom,
    header.open .header__top {
        background: transparent;
    }

    header.open .header__top {
        height: 35vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    header.open .header__container {
        gap: 30px;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }

    header.open .header__bottom * {
        color: #393A3A;
    }

    header .header__menu,
    header .header__social,
    header .header__bottom {
        display: none;
    }

    header .header__burger {
        width: 30px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    header .header__burger span {
        display: block;
        height: 3px;
        width: 26px;
        background-color: #3A3A3A;
        transition: all 0.3s ease-in-out;
    }

    header.open .header__burger {
        width: 40px;
        height: 24px;
    }

    header.open .header__open span {
        height: 2px;
        width: 100%;
        background-color: #3A3A3A;
    }

    header.open span:first-child {
        transform: rotate(-45deg);
        transform-origin: 30px 2px;
    }

    header.open span:nth-of-type(2) {
        display: none;
    }

    header.open span:last-child {
        transform: rotate(45deg);
        transform-origin: 26px 2px;
    }

    header.open .header__menu,
    header.open .header__social,
    header.open .header__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    header.open .header__menu ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    header .header__bottom {
        padding: 30px 0 0;
    }
}
