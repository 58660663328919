.contact-form {
    width: 48%;
    padding: 80px;
    border: 1px solid rgba(110, 110, 110, 0.47);
    border-radius: 8px;
    box-shadow: 10px 8px 20px rgba(0, 0, 0, 0.7);
}

.contact-form .submit-btn {
    margin-top: 35px;
    padding: 15px 0;
}

.form-group {
    margin-bottom: 20px;
}

.form-group .error {
    font-size: 12px;
    color: red;
    text-align: center;
}

label {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
}

input, textarea {
    width: 100%;
    padding: 15px;
    background: #e1e6e9;
    border: 2px solid #9fa7a9;
    border-radius: 30px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
}

input:focus, textarea:focus {
    border-color: #595b5e;
    outline: none;
    box-shadow: 0 0 5px rgba(72, 73, 74, 0.5);
}

textarea {
    resize: none;
}

.submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.contact-form .answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 32vh;
    left: 37vw;
    background-color: #ffffff;
    padding: 50px;
    z-index: 50;
}

.contact-form .answer p:first-child {
    margin-bottom: 30px;
}

.btn-dialog {
    padding: 7px 20px;
    background: linear-gradient(to left, #dee2e2, #939597);
    border-radius: 30px;
    font-size: 16px;
}

.btn-dialog:hover {
    background: linear-gradient(to left, #64656a, #e1e6e9);
}

.contact-form .answer .btn-dialog {
    margin-top: 60px;
    padding: 2px 50px;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    .contact-form {
        order: -1;
        margin-bottom: 30px;
    }

    .contact-form input, textarea {
        font-size: 13px;
    }

    .contact-form .submit-btn {
        margin-top: 20px;
        padding: 10px 0;
        font-size: 13px;
        font-weight: bold;
    }

    .contact-form .answer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: fixed;
        top: 37vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        padding: 15px;
        z-index: 50;
        font-size: 14px;
    }

    .contact-form .answer p:first-child {
        margin-bottom: 30px;
    }

    .contact-form .answer .thanks {
        margin-bottom: 10px;
        font-weight: bold;
    }

    .contact-form .answer .btn-dialog {
        margin-top: 60px;
        padding: 2px 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .contact-form {
        width: 45%;
        padding: 50px 25px 60px;
    }

    .contact-form .btn {
        font-size: 13px;
        font-weight: bold;
        margin-top: 20px;
        padding: 11px 0;
    }

    .contact-form .answer .btn-dialog {
        margin-top: 60px;
        padding: 2px 50px;
    }

    .contact-form input, textarea {
        padding: 12px;
        font-size: 13px;
    }
}
