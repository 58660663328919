.service {
    background: linear-gradient(to top, #ffffff, #b2b8bb);
}

.services-container {
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(to top, #ffffff, #b2b8bb);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.services-title {
    text-align: center;
    font-size: 2.5rem;
    color: #FFFFFF;
}

.service-category {
    margin-bottom: 20px;
}

.service-category h2 {
    font-size: 1.5rem;
    color: #6e6e6e;
    margin-bottom: 10px;
    position: relative;
    padding-left: 15px;
}

.service-category ul {
    list-style-type: disc;
    padding-left: 40px;
}

.service-category ul li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 8px;
}

.service-category ul li::marker {
    color: #737272;
}

@media (max-width: 768px) {
    .services-container {
        padding: 10px;
    }

    .services-title {
        font-size: 2rem;
    }

    .service-category h2 {
        font-size: 1.6rem;
    }

    .service-category ul li {
        font-size: 1.1rem;
    }
}

/* New style */

.service-wrapper {
    width: 80%;
    display: grid;
    margin: 0 auto;
    padding: 40px 0;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 60px;
}
.service-item {
    background: linear-gradient(to top, #e8e8e8, #9f9f9f);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: left;
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 205px;
}
.service-item strong {
    display: block;
    padding: 0 0 10px 20px;
    font-size: 1.2em;
    text-align: center;
    color: #595b5e;
}

.service-item ul {
    padding-left: 10px;
    list-style-type: none;
}

.service-item ul li {
    margin: 5px 0;
    font-style: italic;
}

.service-item ul li:before {
    content: '☑️';
    display: inline-block;
    margin-right: 10px;

}
.tooth-icon {
    width: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .service-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .services-title {
        font-size: 30px;
    }
}

@media screen and (max-width: 477px) {
    .main section.service {
        padding: 0;
    }

    .service-wrapper {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 30px;
    }

    .services-title {
        font-size: 25px;
    }

    .service-item {
        min-height: 100px;
    }

    .service-item strong {
        font-size: 1em;
    }
}