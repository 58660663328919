.slider {
    background: linear-gradient(to top, #FFFFFF, #b2b8bb);
    padding: 60px 0;
}

.slider .swiper {
    width: 100%;
    padding: 10px 50px 30px;
}

.swiper-pagination-bullet-active {
    background-color: gray;
}

.swiper-button-prev:after, .swiper-button-next:after {
    color: gray;
    font-size: 25px;
}

.swiper-button-prev:after, .swiper-button-next::after {
    color: #b2b8bb;
    font-size: 25px;
}

.slider img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.slider__container {
    flex-direction: column;
    align-items: center;
}

.slider__container h2 {
    width: 100%;
    text-align: center;
}

.slider__container h2::after {
    height: 2px;
    width: 100%;
}

/************************/

@media screen and (max-width: 477px) {
    .slider {
        padding: 25px 0;
    }

    .slider .slider__container {
        gap: 0;
    }

    .slider h2 {
        font-size: 21px;
    }

    .slider img {
        height: 300px;
    }

    .slider .swiper {
        padding: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .slider {
        padding: 50px 0;
    }

    .slider .swiper {
        padding: 10px 35px 30px;
    }
}