.about-second {
    background: linear-gradient(to top, #ffffff, #b2b8bb);
    padding: 100px 0;
}

.about-second__image {
    width: 50%;
    padding: 0 25px;
}

.about-second__image img {
    width: 100%;
}

.about-second__description {
    width: 50%;
    padding: 0 25px;
}

.about-second__description p {
    font-size: 14px;
}

.about-second__description p:nth-of-type(2) {
    margin-top: 15px;
}

.about-second__list {
    margin: 15px 0;
    list-style-type: none;
}

.about-second__list .list__item {
    font-style: italic;
}

@media screen and (max-width: 477px) {
    .about-second {
        padding: 25px 0;
    }

    .about-second__image {
        padding: 0;
        width: 90%;
    }

    .about-second__image img {
        object-fit: cover;
        height: 300px;
    }

    .about-second h2 {
        font-size: 21px;
    }

    .about-second__description {
        width: 100%;
        padding: 0;
    }

    .about-second__description p, ul li {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .about-second {
        padding: 50px 0;
    }

    .about-second__container {
        flex-direction: column;
    }

    .about-second__image {
        width: 50%;
        padding: 0;
    }

    .about-second__description {
        padding: 25px 0 0 0;
        width: 100%;
    }

    .about-second__description p, ul li {
        font-size: 14px;
    }
}