.cover {
    height: 90vh;
    background: url('../../assets/images/img_3.jpeg') no-repeat top/cover fixed;
    position: relative;
}

.cover::after {
    content: '';
    background: rgba(2, 2, 2, .6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cover .cover__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 100px;
}

.cover__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
}

.cover__content h2 {
    color: #FFFFFF;
}

.cover__content h2::after {
    width: 18%;
    margin: 5px 0 20px auto;
    background: #FFFFFF;
}

.cover__content p {
    color: #b2b8bb;
    font-size: 19px;
    font-style: italic;
}

/********* MEDIA ***********/

@media screen and (max-width: 477px) {
    .cover {
        height: 55vh;
    }

    .cover .cover__container {
        padding-bottom: 25px;
    }

    .cover__content h2 {
        font-size: 18px;
    }

    .cover__content p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    .cover {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .cover {
        height: 70vh;
    }

    .cover__content p {
        font-size: 16px;
    }
}