.recording-form {
    width: 40%;
    z-index: 10;
}

.recording-form .submit-btn {
    margin-top: 35px;
    padding: 15px 0;
}

.form-group {
    margin-bottom: 20px;
}

.form-group .error {
    font-size: 12px;
    color: red;
    text-align: center;
}

.recording-form input {
    width: 100%;
    padding: 15px;
    background: rgba(89, 91, 94, 0.91);
    color: #b2b8bb;
    border-radius: 30px;
    border: none;
    font-size: 14px;
}

.recording-form input::placeholder {
    color: #b2b8bb;
}

.recording-form input:focus {
    border-color: #595b5e;
    outline: none;
    box-shadow: 0 0 5px rgba(72, 73, 74, 0.5);
}

.submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.recording-form .answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 32vh;
    left: 37vw;
    background-color: #ffffff;
    padding: 50px;
    z-index: 50;
}

.recording-form .answer p:first-child {
    margin-bottom: 30px;
}

.btn-dialog {
    padding: 7px 20px;
    background: linear-gradient(to left, #dee2e2, #939597);
    border-radius: 30px;
    font-size: 16px;
}

.btn-dialog:hover {
    background: linear-gradient(to left, #64656a, #e1e6e9);
}

.recording-form .answer .btn-dialog {
    margin-top: 60px;
    padding: 2px 50px;
}

/************** MEDIA **************/

@media screen and (max-width: 477px) {
    .recording-form input {
        font-size: 13px;
    }

    .recording-form .answer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: fixed;
        top: 37vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        padding: 15px;
        z-index: 50;
        font-size: 14px;
    }

    .recording-form .answer p:first-child {
        margin-bottom: 30px;
    }

    .recording-form .answer .thanks {
        margin-bottom: 10px;
        font-weight: bold;
    }

    .recording-form .answer .btn-dialog {
        margin-top: 60px;
        padding: 2px 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .recording-form {
        padding: 10px;
    }

    .recording-form .submit-btn {
        font-size: 14px;
        font-weight: bold;
        margin-top: 15px;
        padding: 10px 0;
    }

    .recording-form input {
        padding: 12px;
        font-size: 13px;
    }
}
