.contacts {
    background: linear-gradient(to top, #FFFFFF, #b2b8bb);
}

.contacts-title {
    color: #FFFFFF;
}

.contacts .contacts__container {
    flex-direction: column;
    padding: 80px 0;
}

.contacts .box {
    display: flex;
    align-items: center;
    gap: 8px;
}

.contacts .box img.icon {
    margin-bottom: 25px;
}

.contacts .box img {
    height: 20px;
}

.contacts-description {
    text-align: center;
}

.contacts-description h2::after {
    height: 4px;
    width: 18%;
    margin: 20px auto;
}

.contacts-description p {
    font-size: 19px;
    font-weight: 500;
}

.contacts-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.contacts .content__map {
    width: 48%;
}

.contacts .content__map h3 {
    margin: 20px 0;
    color: #595b5e;
    font-size: 21px;
    font-weight: 600;
}

.contacts .content__map p {
    margin: 10px 0;
    color: #595b5e;
    font-size: 17px;
    font-weight: 600;
}

/******** MEDIA ********/

@media screen and (max-width: 477px) {
    .main section.contacts {
        padding: 0;
    }

    .contacts .contacts__container, .main section.big-map {
        padding: 20px 20px 0;
    }

    .content-address {
        order: 1;
    }

    .contacts .content__map h3 {
        font-size: 19px;
    }

    .contacts .content__map p {
        font-size: 14px;
    }

    .contacts-description h2::after {
        margin: 5px auto 15px;
        height: 3px;
    }

    .contacts-description p {
        font-size: 15px;
    }

    .contacts h2 {
        font-size: 21px;
        font-family: 'Montserrat', sans-serif;
    }

    .contacts-content {
        flex-direction: column;
    }

    .contacts .content__map {
        width: 100%;
    }

    .contacts .content__map iframe {
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    main.main .contacts .contacts__container {
        padding: 25px;
    }

    .contacts-content {
        margin-top: 30px;
    }

    .big-map {
        padding: 25px;
    }

    .contacts .content__map iframe {
        width: 100%;
    }

    .contacts .content__map p {
        font-size: 15px;
    }
}